import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import ReactPaginate from "react-paginate";
import axios from "axios"



const Transactionhistory = () => {
    const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    if (nodeMode === "development") {
        var baseUrl = beckendLocalApiUrl;
    } else {
        baseUrl = beckendLiveApiUrl;
    }
    //eslint-disable-next-line
    const [user, setUser] = useState()
    //use for pagination..
    let limit = 50;
    const [pageNumber, setPageNumber] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const role = async () => {
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        await axios.get(baseUrl + `me`, { headers })
            .then((res) => {
                setUser(res.data)

                //Allgames(res.data._id)
                Allgames(res.data._id, pageNumber, limit)
                // window.location.reload()

            })

    }

    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        setPageNumber(currentPage);
        // scroll to the top
        //window.scrollTo(0, 0)
    };

    const dateFormat = (e) => {

        const date = new Date(e);
        const newDate = date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
        return newDate;
    }

    const [cardData, setGame] = useState()

    const Allgames = async (id) => {
        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        await axios.get(baseUrl + `temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, { headers })
            .then((res) => {
                setGame(res.data.ress)
                setNumberOfPages(res.data.totalPages);

            })

    }
    
    const checkdeposit = (order_id, order_token, pay_date) => {

    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    axios.post(baseUrl + `depositupipay/response`, { order_id, order_token, pay_date }, { headers })
      .then((res) => {
        const icon = res.data.status == "PAID" ? "success" : "danger";
        const title = res.data.status == "PAID" ? "Deposit submited successfully" : "Transaction Failed";
        profle();

      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('token');
          window.location.reload()
        }
      })
  }

    const universalCheckDeposit = (payment_gatway, order_id, order_token, pay_date) => {
        console.log(payment_gatway, order_token, order_id, pay_date);
        //alert(payment_gatway);
        if (payment_gatway == 'razorpay') {
            checkrazorpayPayment(order_id, order_token, pay_date)
        }
        else if (payment_gatway == 'upigateway') {
            checkupigatewaypay(order_id, order_token, pay_date)
        }else if (payment_gatway == 'Phonepe') {
            checkphonepe(order_id, order_token, pay_date)
        }
        else {
            alert('Nothing found');
        }
    }

    const checkphonepe = (order_id, order_token) => {

        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.post(baseUrl + `phonepe-callback`, { order_id, order_token }, { headers })
            .then((res) => {
                const icon = res.data.status == "PAID" ? "success" : "danger";
                const title = res.data.status == "PAID" ? "Deposit submited successfully" : "Transaction Failed";
                role();
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('token');
                    window.location.reload()
                }
            })
    }

    const checkrazorpayPayment = (order_id, order_token) => {

        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.post(baseUrl + `razorpaycheck/response`, { order_id, order_token }, { headers })
            .then((res) => {
                const icon = res.data.status == "PAID" ? "success" : "danger";
                const title = res.data.status == "PAID" ? "Deposit submited successfully" : "Transaction Failed";
                profle();
            })
            .catch((e) => {
                if (e.response.status == 401) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('token');
                    window.location.reload()
                }
            })
    }



    const checkupigatewaypay = (order_id, order_token, pay_date) => {


        const access_token = localStorage.getItem("token")
        const headers = {
            Authorization: `Bearer ${access_token}`
        }
        axios.post(baseUrl + `depositupipay/response`, { order_id, order_token }, { headers })
            .then((res) => {
                const icon = res.data.status == "PAID" ? "success" : "danger";
                const title = res.data.status == "PAID" ? "Deposit submited successfully" : "Transaction Failed";
                role();

            })
            .catch((e) => {
                if (e.response.status == 200) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('token');
                    window.location.reload()
                }
            })
    }


    
    const newdateFormat = (e) => {

        let today = new Date(e);
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '-' + mm + '-' + yyyy;
        return today;
    }



    useEffect(() => {
        role()
        //eslint-disable-next-line
    }, [pageNumber, limit])


    return (
        <div>
            <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
                {/* pagination */}

                <div className="pt-5">
                    <div className="mt-4">
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={numberOfPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-center"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>

                {/* game-cards */}
                {cardData && cardData.map((card, index) => {

                    var id = card._id.toString(), ctr = 0;
                    var timestamp = id.slice(ctr, (ctr += 8));
                    var machineID = id.slice(ctr, (ctr += 6));
                    var processID = id.slice(ctr, (ctr += 4));
                    var counter = id.slice(ctr, (ctr += 6));
                    //console.log("id:", id);
                    //console.log("timestamp:", parseInt(timestamp, 16));
                    //console.log("machineID:", parseInt(machineID, 16));
                    //console.log("processID:", parseInt(processID, 16));
                    var counterid = parseInt(timestamp, 16);


                    if ((card.Req_type === 'deposit' || card.Req_type === 'bonus') && card.status != 'FAILED') {
                        var titleMsg = <div className="text-success">Cash added</div>;
                        if (card.status === 'Pending' && card.Req_type === "deposit") {
                            var signIcon = <div className="text-danger">(X)</div>;

                        } else {
                            var signIcon = <div className="text-success">(+)</div>;
                        }
                    }
                    else if (card.Req_type === "withdraw" && card.status != 'FAILED') {
                        var titleMsg = 'Withdraw Using ' + card.Withdraw_type;
                        var signIcon = <div className="text-danger">(-)</div>;
                    }
                    else if (card.Req_type === 'penalty' && card.status != 'FAILED') {
                        var titleMsg = 'Penalty';
                        var signIcon = <div className="text-danger">(-)</div>;
                    }
                    else if (card.status === 'Pending' || card.status === 'FAILED') {
                        var titleMsg = 'FAILED';
                        var signIcon = <div className="text-danger">(X)</div>;
                    }
                    else {
                        var titleMsg = '';
                        var signIcon = <div className="text-success"></div>;
                    }

                    return (
                        <div key={index}
                            className={`w-100 mt-3 py-3 d-flex align-items-center ${css.list_item}`}
                        >
                            {/* map the cardData */}
                            <div className={`${css.center_xy} ${css.list_date} mx-2`}>
                                <div>{dateFormat(card.createdAt).split(',')[0]}</div>
                                <small>{dateFormat(card.createdAt).split(',')[1]}</small>

                            </div>
                            <div className={`${css.list_divider_y}`} />
                            <div className={`mx-3 d-flex ${css.list_body}`}>
                                <div className="d-flex align-items-center">
                                    <picture className="mr-2">
                                        <img
                                            height="32px"
                                            width="32px"
                                            src={process.env.PUBLIC_URL + '/Images/LandingPage_img/ludo.jpeg'}
                                            alt=""
                                            style={{ borderRadius: "5px" }}
                                        />
                                    </picture>
                                </div>

                                <div className="d-flex flex-column font-8">
                                    <div>
                                        <b>
                                            {titleMsg}
                                        </b>
                                        <h6>OrderID: JP-{counterid}</h6>
                                    </div>
                                    <div className={`${css.games_section_headline}`}>
                                        Status:{(card.status === 'none' || (card.status === 'Pending' && card.Req_type === "withdraw")) ? 'Processing' : (card.status === 'Pending' && card.Req_type === "deposit") ? card.status : card.status}
                                        <br></br>
                                        {(card.txn_msg) ? card.txn_msg : ''}
                                    </div>
                                </div>
                            </div>

                            <div className="right-0 d-flex align-items-end pr-3 flex-column">

                                {/*{card.status === 'Pending' && card.Req_type === "deposit" &&
                                    // <button className={`btn btn-sm ${css.btn_success} ${css.status_badge}`}>
                                    //   Check Status
                                    // </button>

                                    <button className="btn btn-danger" onClick={() => universalCheckDeposit(card.payment_gatway, card.order_id, card.order_token, newdateFormat(card.createdAt))} >Check Status {card.payment_gatway}</button>
                                }*/}


                                <div className="d-flex float-right font-8">
                                    {signIcon}
                                    <picture className="ml-1 mb-1">
                                        <img
                                            alt="img"
                                            height="21px"
                                            width="21px"
                                            src={process.env.PUBLIC_URL + '/Images/LandingPage_img/global-rupeeIcon.png'}
                                            className="snip-img"
                                        />
                                    </picture>
                                    <span className="pl-1">{card.amount}</span>
                                </div>
                                {card.closing_balance && <div
                                    className={`${css.games_section_headline}`}
                                    style={{ fontSize: "0.6em", whiteSpace: 'nowrap' }}
                                >
                                    closing balance :{card.closing_balance}
                                </div>}
                                <div
                                    className="games-section-headline"
                                    style={{ fontSize: "0.6em" }}
                                >

                                </div>
                            </div>
                        </div>
                    );
                })
                }
                {cardData && cardData.length === 0 &&
                    <div className="text-center">
                        <img
                            src={process.env.PUBLIC_URL + '/Images/notransactionhistory.png'}
                            alt="no data"
                            width={300}
                            height={300}
                            className="img-fluid "
                            style={{ marginTop: "25%" }}
                        />
                        <div className="mt-2">
                            <h3 className="font-weight-bold">No transaction History</h3>
                            <p className="text-muted"> You have not made any transaction  yet.</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Transactionhistory;
